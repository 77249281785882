<template>
  <div class="form-content">
    <v-sheet
      color="white"
      elevation="2"
      class="tw-w-full tw-rounded"
    >
      <v-container class="tw-relative">
        <v-progress-linear
          :active="isProcessing"
          :indeterminate="isProcessing"
          absolute
          top
          color="grey darken-4"
          class="tw-rounded-t-xl"
        />
        <validation-observer
          ref="observer"
          v-slot="{ invalid }"
        >
          
          <form @submit.prevent="doRegister">
            <v-row>
              <v-col cols="12" class="tw-pb-6 tw-pt-0 tw-px-6 md:tw-py-10">
                <validation-provider
                  v-slot="{ errors }"
                  name="email"
                  rules="required|email"
                >
                  <v-text-field
                    :class="{'vee-error': errors.length}"
                    :value="email"
                    :error-messages="errors"
                    label="E-mail"
                    placeholder="Email..."
                    readonly
                    outlined
                    required
                    @input="(x) => doUpdateData(x, 'email')"
                  ></v-text-field>
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  name="code"
                  rules="required|min:6|numeric"
                >
                  <v-text-field
                    :class="{'vee-error': errors.length}"
                    :value="form.code"
                    :error-messages="errors"
                    label="code"
                    type="text"
                    placeholder="Enter code here..."
                    outlined
                    required
                    @input="(x) => doUpdateData(x, 'code')"
                  ></v-text-field>
                </validation-provider>
                <div>
                  <v-btn
                    class="tw-w-full tw-mb-3"
                    color="primary"
                    type="submit"
                    :disabled="invalid"
                    large
                  >
                    Confirm
                  </v-btn>
                </div>
                <div class="tw-text-left tw-py-0 tw-flex tw-justify-between">
                  <div>
                    <v-btn 
                      plain
                      class="tw-cursor-pointer"
                      :disabled="isResend"
                      @click="doResendCode()"
                    >
                      <small>Resend Code</small>
                    </v-btn>
                    <span v-if="isResend" class="tw-px-1">
                      <small>after {{timeleft}} seconds</small>
                    </span>
                  </div>
                  <div>
                    <v-btn 
                      plain
                      class="tw-cursor-pointer"
                    >
                      <router-link class="tw-no-underline" :to="{ name: ROUTE_SIGNIN }">
                        <small>Log In</small>
                      </router-link>
                      
                    </v-btn>
                  </div>
                </div>
                <!-- <v-btn @click="clear">
                  clear
                </v-btn> -->
              </v-col>
            </v-row>
          </form>
        </validation-observer>
      </v-container>
    </v-sheet>
  </div>
</template>

<script>

//utilities
import {
  ROUTE_SIGNIN,
} from '@/constants'

export default {
  name : 'SignUpForm',
  props: [
    'form',
    'doRegister',
    'doUpdateData',
    'doResendCode',
    'isProcessing',
    'email',
    'timeleft',
    'isResend',

  ],

  data: () => ({
    //not state management data
    ROUTE_SIGNIN: ROUTE_SIGNIN,
  }),

}
</script>

<style>

</style>